body {
  letter-spacing: normal;
  overflow: hidden;
  @media only screen and (min-width: 786px) and (max-width: 1300px) {
    overflow-y: scroll;
  }
}

.login-container {
  margin-top: 10%;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 50%;
  margin-bottom: 30%;
}

.layer {
  background-color: rgba(99, 24, 51, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container-wrap {
  padding: 16px;
  border-top: 8px solid#cf2030;
  border-radius: 8px;

  @media (min-width: 648px) {
    padding: 32px;
    border-top: 12px solid#cf2030;
    border-radius: 8px;
  }
}
.heading-text {
  margin-top: 20%;
  text-align: center;
}

#fl {
  font-weight: 100;
  font-size: 1.25em;
}

#sl {
  font-weight: 100 !important;
}

.profile-img {
  text-align: center;
}

.profile-img img {
  border-radius: 50%;
  /* animation: mymove 2s infinite; */
}

@keyframes mymove {
  from {
    border: 1px solid #f2f3f4;
  }
  to {
    border: 8px solid #f2f3f4;
  }
}

.login-form {
  padding: 8%;
  background: #ffffff;
  border-radius: 0 18px 18px 0;
}

.form-control {
  font-size: 14px;
  border: 2px solid #631833;
  border-radius: 10px;
}

.forget-password a {
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
}

.login-heading {
  margin-top: 30px;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-top-padding {
  padding-top: 5%;
}

.login-bottom-padding {
  padding-bottom: 5%;
}

.form-control::placeholder {
  color: #631833;
}

.form-button {
  color: #fff;
  background-color: #631833;
  border-color: #631833;
  border-radius: 14px;
  font-size: 1rem;
}

.form-align-center {
  text-align: center;
}

.form-text-color {
  color: #cf2030;
}

.top-margin-30 {
  margin-top: 30px;
}

.successIcon {
  color: green;
}

.bnicolor {
  color: #cf2030;
}

.bni-background-color {
  background-color: #cf2030 !important;
}

.bni-secondary-color {
  background-color: #64666a !important;
}

.bni-tertiary-color {
  background-color: #c8c8c8 !important;
}

.launcher-color {
  color: #631833;
}

hr {
  border-top: 1px solid #631833;
}

.report_launcher {
  fill: #4285f4;
}

.align-center {
  text-align: center;
}

.terms-container {
  margin-top: 8%;
  margin-left: 33%;
}

.terms-form {
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 30px 30px 30px 30px;
}

.terms-align-center {
  text-align: center;
}

.terms-align-padding {
  padding: 10%;
}

.terms-align-margin {
  margin-right: 8%;
}

.terms-padding-bottom {
  padding-bottom: 5%;
}

.term-cancel-button {
  color: #fff;
  background-color: #c5d0d0;
  border-color: #c5d0d0;
  border-radius: 14px;
  font-size: 1rem;
}

.term-accept-button {
  color: #fff;
  background-color: #25d366;
  border-color: #25d366;
  border-radius: 14px;
  font-size: 1rem;
}

.white-color {
  color: #fff;
}

.link-style {
  color: #0b0b0b;
  text-decoration: underline;
  :hover {
    color: #0b0b0b;
    text-decoration: underline;
  }
}

.profile-dropdown {
  [class^='MuiPaper-root'] {
    margin-top: 38px;
    width: 160px;
  }
}

.app-btn {
  padding: 8px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: normal;
  text-decoration: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: border-color 83ms linear, box-shadow 83ms linear;
  color: #000000;
  img {
    display: block;
    margin: 0 auto;
  }
  &:hover {
    border-color: #f4f4f4 !important;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    transition: border-color 0.167s linear, box-shadow 0.167s linear;
  }
}
header {
  .brand {
    display: block;
    margin-left: 40px;
    margin-top: 4px;
  }
  .tagline {
    margin: 0 auto;
  }
}

.app-menu-container {
  margin-top: 16px;
  padding: 0px 12px;
}

.button-focus-disable {
  outline: none !important;
}
.super-graphic-bg {
  background-image: url('../static/imgs/super_graphic.svg');
  background-position: right bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.lang-chooser {
  margin-left: 4px;
  margin-top: 20px;
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }
  .MuiSelect-selectMenu {
    background: transparent !important;
  }
  .MuiInputBase-root {
    font-size: 16px;
    color: #000000;
  }
}

footer {
  background-color: #cf2030;
  display: flex;
  position: fixed;
  width: 100% !important;
  height: 55px;
  bottom: 0;
  padding: 3px 20px;
  color: #ffffff;
  justify-content: flex-end;

  .lang-chooser {
    display: flex;

    .MuiInput-underline:after,
    .MuiInput-underline:before {
      display: none;
    }
    .MuiInputBase-root {
      font-size: 16px;
      color: #ffffff;
    }
  }
  .links {
    margin-left: 10px;
    margin-top: 9px;
  }
}

.info-text {
  display: inline;
  color: inherit !important;
  min-width: auto !important;
  font-size: inherit !important;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  &:hover {
    backgroundcolor: transparent;
  }
  &:focus {
    outline: none;
  }
}

#underline {
  text-decoration: underline !important;
}

#sysdetails-popover {
  position: relative;
  [class^='MuiPaper-root'] {
    padding: 26px;
    width: 340px;
    position: relative;
    margin-top: -54px;
    box-shadow: 0px 3px 6px #00000029 !important;
  }
  [class^='MuiPopover-paper'] {
    outline: 0;
    padding: 26px !important;
    position: absolute;
    max-width: 340px !important;
    min-width: 340px !important;
  }

  [class^='pop-over-aarow'] {
    position: relative;
  }
}

#sysdetails-popover {
  [class^='MuiPaper-root'] {
    &::before {
      content: '';
      position: absolute;
      top: -webkit-calc(100% - 16px); /*may require prefix for old browser support*/
      top: calc(100% - 16px); /*i.e. half the height*/
      right: 22px;
      height: 30px;
      width: 30px;
      background: #ffffff;
      transform: rotate(45deg);
    }
  }
}
